import { createContext, useContext, useEffect } from 'react';

const CustomerioContext = createContext(null);

export function CustomerioProvider({ siteId, children }) {

  useEffect(() => {
    // Initialize _cio object
    var _cio = (window._cio = window._cio || []);
    (function() {
      var a,b,c;a=function(f){return function(){_cio.push([f].
      concat(Array.prototype.slice.call(arguments,0)))}};
      b=["load","identify","sidentify","track","page","on","off"];
      for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])}
    })();
    
    // Create and insert script tag
    const script = document.createElement('script');
    const firstScript = document.getElementsByTagName('script')[0];
    
    script.async = true;
    script.id = 'cio-tracker';
    script.setAttribute('data-site-id', siteId);
    script.setAttribute('data-use-array-params', 'true');
    script.setAttribute('data-use-in-app', 'true');
    script.setAttribute('data-auto-track-page', 'false');
    script.src = 'https://assets.customer.io/assets/track.js';
    
    // Insert before the first script tag instead of appending to head
    firstScript.parentNode.insertBefore(script, firstScript);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [siteId]);

  const value = {
    cioIdentify: (userData) => {
      if (window._cio) {
        window._cio.identify(userData);
      }
    },
    cioTrack: (eventName, attributes = {}) => {
      if (window._cio) {
        window._cio.track(eventName, attributes);
      }
    },
    cioPage: (pageData) => {
      if (window._cio) {
        window._cio.page(pageData);
      }
    }
  };

  return (
    <CustomerioContext.Provider value={value}>
      {children}
    </CustomerioContext.Provider>
  );
}

export function useCustomerio() {
  const context = useContext(CustomerioContext);
  if (!context) {
    throw new Error('useCustomerio must be used within a CustomerioProvider');
  }
  return context;
}