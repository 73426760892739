import axios from 'axios';
import { Mixpanel } from './Mixpanel'
import { clerk } from './clerkConfig';

function CreateAxiosInstance(baseURL, useAuth = true) {
  const instance = axios.create({
    timeout: 90000,
    baseURL: baseURL ? baseURL : null,
  });

  // Add clerk token to every request
  instance.interceptors.request.use(
    async (config) => {
      config.headers['LD-Session'] = localStorage.getItem('diffitSessionId');
      const distinctId = await Mixpanel.getDistinctId();
      config.headers['X-Mixpanel-Distinct-ID'] = distinctId;
      if (!useAuth) {
        return config;
      }
      await clerk.load();
      if (!clerk.session) return config;
      const bearerToken = await clerk.session.getToken({
        template: 'userinfo',
      }); // Get the current session's token
      if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
      }
      return config;
    },
    (error) => {
      console.error(error);
      Promise.reject(error);
    }
  );

  // add error message handler. accepts a string or a function
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error);

      const errorMessageHandler = error.config.customErrorHandler;

      if (errorMessageHandler) {
        if (typeof errorMessageHandler === 'function') {
          errorMessageHandler(error);
        } else if (typeof errorMessageHandler === 'string') {
          alert(errorMessageHandler);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
}

export default CreateAxiosInstance;
