// Generic image component
// Will properly update path if we want to host from CDN instead of S3
import { forwardRef } from 'react';

export const getSrc = (src) => {
  if (!src || !src.includes) {
    return '';
  }
  const imageSrc = src.image_url ? src.image_url : src;
  if (imageSrc.includes('diffit-pdf-upload-dev.s3.amazonaws.com')) {
    return imageSrc.replace('diffit-pdf-upload-dev.s3.amazonaws.com', 'images-dev.diffit.me');
  } else if (imageSrc.includes('diffit-pdf-upload-prod.s3.amazonaws.com')) {
    return imageSrc.replace('diffit-pdf-upload-prod.s3.amazonaws.com', 'images.diffit.me');
  } else {
    return imageSrc;
  }
};

const Image = forwardRef(({ src, ...props }, ref) => {
  return <img ref={ref} src={getSrc(src)} {...props} loading='lazy' />;
});

Image.displayName = 'Image';

export default Image;