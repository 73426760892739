import { useContext, useEffect, useState } from 'react';
import BasicModal from './modals/BasicModal';
import { EnterSchoolInfo } from './EnterSchoolInfo';
import {
  getUserSettings,
  saveUserSettings,
  subscribeToMailerlite,
} from '../services/UserSettingsService';
import { markUserNuxAsSeen } from '../services/UserService';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import { Mixpanel } from '../services/Mixpanel';
import { useAuth } from '@clerk/clerk-react';
import Image from './Image';
import { hasActiveSubscription, isInPremiumOrg } from '../utils/isPremiumUser';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import LoadingSpinner from './LoadingSpinner';
import { clearActivitiesCache } from '../routes/Activities/activities';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { deleteUserSchoolRecord } from '../services/SchoolRecordService';

// See segments.py for the list of subjects and grade ranges
const GRADE_RANGES = ['K-2', '3-5', '6-8', '9-12', 'Other'];
const SUBJECTS = ['English Language Arts', 'Social Studies', 'Science', 'Math'];
const OTHER_SUBJECTS = ['English Language Learners', 'Special Education', 'World Language', 'Other'];

const BANNER_MESSAGES = [
  'Welcome to Diffit!',
  'Help us personalize your experience',
  'Last question!',
];
const RETURNING_USER_BANNER_MESSAGE = 'Thanks for updating your information';
const RETURNING_USER_MESSAGES = [
  'Welcome back! Please confirm your school or district',
  'What grades do you teach?',
  'What subjects do you teach?',
];
const MESSAGES = [
  'Welcome! Please confirm your school or district',
  'What grades do you teach?',
  'What subjects do you teach?',
  'Welcome!',
];
const GRADES_TO_RANGES = {
  'K-2': 'K to 2',
  '3-5': '3 to 5',
  '6-8': '6 to 8',
  '9-12': '9 to 12',
  Other: 'Other',
};

export const OnboardingWizard = ({ showWizard, setShowWizard }) => {
  const { diffitUser, refreshDiffitUser } = useContext(DiffitUserContext);
  const { isSignedIn } = useAuth();
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedGradeRange, setSelectedGradeRange] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [schoolSelectionFormStep, setSchoolSelectionFormStep] = useState('');
  const [schools, setSchools] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddingNewSchool, setIsAddingNewSchool] = useState(false);
  const { requireReturningUserOnboarding } = useFlags();

  useEffect(() => {
    if (!isSignedIn) return;
    refreshUserSettings();
  }, [isSignedIn, diffitUser]);

  useEffect(() => {
    if (diffitUser?.seen_nux_json) {
      const seenNux = JSON.parse(diffitUser.seen_nux_json);
      if (seenNux['new_user_required_onboarding'] && !seenNux['user_completed_onboarding']) {
        Mixpanel.track('Onboarding Wizard - First Time User', {
          event_category: 'onboarding',
          key_action: 'first_time_user',
          is_action: false,
        });
        setIsFirstTimeUser(true);
      }
      if (!seenNux['user_completed_onboarding']) {
        Mixpanel.track('Onboarding Wizard - Returning User Required', {
          event_category: 'onboarding',
          key_action: 'returning_user',
          is_action: false,
        });
        setHasCompletedOnboarding(false);
      } else {
        Mixpanel.track('Onboarding Wizard - Returning User Completed', {
          event_category: 'onboarding',
          key_action: 'returning_user_completed',
          is_action: false,
        });
        setHasCompletedOnboarding(true);
      }
    }
  }, [diffitUser]);

  const handleSaveSettings = async (setting) => {
    if (setting === 'gradeLevels') {
      await saveUserSettings({
        gradeLevels: selectedGradeRange,
      });
    } else if (setting === 'subjects') {
      await saveUserSettings({
        subjects: selectedSubjects,
      });
    }
    clearActivitiesCache();
    await refreshDiffitUser();
  };

  const refreshUserSettings = () => {
    setIsLoading(true);
    getUserSettings().then((response) => {
      setIsLoading(false);
      setSchools(response.data.schools);
      setSelectedGradeRange(response.data.grade_levels);
      setSelectedSubjects(response.data.subjects);
    });
  };

  const handleSubmission = async () => {
    setIsSubmitting(true);
    if (hasActiveSubscription(diffitUser)) {
      await markUserNuxAsSeen(diffitUser.id, [
        'welcome_to_diffit',
        'welcome_to_premium',
        'user_completed_onboarding',
      ]);
    } else {
      await markUserNuxAsSeen(diffitUser.id, ['welcome_to_diffit', 'user_completed_onboarding']);
    }
    await handleSaveSettings('subjects');
    await subscribeToMailerlite();
    if (isFirstTimeUser || showFinalStep) {
      setCurrentStep(3);
    } else {
      setShowWizard(false);
    }
    setIsSubmitting(false);
  };

  const handleDeleteUserSchoolRecord = async (schoolId) => {
    setIsLoading(true);
    try {
      await deleteUserSchoolRecord({ schoolRecordId: schoolId });
    } catch (e) {
      console.error(e);
    }

    refreshUserSettings();
    setIsLoading(false);
  };

  return (
    <BasicModal
      showModal={showWizard}
      setShowModal={setShowWizard}
      isExitable={requireReturningUserOnboarding ? hasCompletedOnboarding : isFirstTimeUser}
      maxHeight=''
      dialogPadding='px-4 pb-4 pt-4'
      maxWidth='sm:max-w-md'
      overflowY=''
      //   Make the icon the "wizard" where the numbers 1-3 are shown and the current step is highlighted
      icon={null}
      iconBackground={'bg-none'}>
      <div className='flex flex-col w-full items-center gap-4'>
        <div className='flex space-x-6'>
          <div
            onClick={() => {
              if (currentStep >= 0) {
                setCurrentStep(0);
              }
            }}
            className={`w-2 h-2 rounded-full flex items-center justify-center ${currentStep >= 0 ? 'cursor-pointer' : ''} ${currentStep === 0 ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}></div>
          <div
            onClick={() => {
              if (currentStep >= 1) {
                setCurrentStep(1);
              }
            }}
            className={`w-2 h-2 rounded-full flex items-center justify-center ${currentStep >= 1 ? 'cursor-pointer' : ''} ${currentStep === 1 ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}></div>
          <div
            className={`w-2 h-2 rounded-full flex items-center justify-center ${currentStep === 2 || currentStep === 3 ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}></div>
        </div>
        <p className='text-gray-500 text-sm mb-2'>
          {isFirstTimeUser ? BANNER_MESSAGES[currentStep] : RETURNING_USER_BANNER_MESSAGE}
        </p>
      </div>
      <hr />
      {/* Step 1 */}
      <p className='text-black text-lg font-medium text-center py-4 px-16'>
        {schoolSelectionFormStep === 'addSchool'
          ? 'Add your school'
          : isFirstTimeUser
            ? MESSAGES[currentStep]
            : RETURNING_USER_MESSAGES[currentStep]}
      </p>
      {currentStep === 0 && (
        <div className='px-6'>
          {isLoading && (
            <div className='flex w-full items-center justify-center'>
              <LoadingSpinner />
            </div>
          )}
          {schools && schools.length > 1 && !isAddingNewSchool && (
            // List out each school, with an option to delete the school
            <div className='flex flex-col gap-4 pt-2'>
              {schools.map((school) => (
                <div key={school.id} className='flex flex-row w-full justify-between items-center'>
                  <div className='flex flex-col items-center justify-between'>
                    <p className='font-medium'>{school.name}</p>
                    <p className='text-gray-500'>{school.location_string}</p>
                  </div>
                  <button
                    className='text-red-500 text-sm'
                    onClick={() => {
                      Mixpanel.track(
                        'Onboarding Wizard - More than One School - Remove School Clicked',
                        {
                          event_category: 'onboarding',
                          key_action: 'remove_school_clicked',
                          is_action: true,
                        }
                      );
                      handleDeleteUserSchoolRecord(school.id);
                    }}>
                    Remove
                  </button>
                </div>
              ))}
              <div className='flex justify-center'>
                <button
                  className='bg-white hover:bg-gray-100 rounded-md w-full p-2 m-2 text-emerald-600 border border-emerald-600 focus:outline-emerald-500'
                  onClick={() => {
                    Mixpanel.track(
                      'Onboarding Wizard - More than One School - Add School Clicked',
                      {
                        event_category: 'onboarding',
                        key_action: 'add_school_clicked',
                        is_action: true,
                      }
                    );
                    setIsAddingNewSchool(true);
                    setSchoolSelectionFormStep('addSchool');
                  }}>
                  Add School
                </button>
                <button
                  className='w-full bg-emerald-600 text-white rounded-md p-2 m-2 disabled:opacity-50'
                  onClick={() => {
                    Mixpanel.track('Onboarding Wizard - More than One School - Continue Clicked', {
                      event_category: 'onboarding',
                      key_action: 'continue_clicked',
                      is_action: true,
                    });
                    setCurrentStep(1);
                  }}>
                  Continue
                </button>
              </div>
            </div>
          )}
          {!isLoading && ((schools && schools.length <= 1) || isAddingNewSchool) && (
            <EnterSchoolInfo
              existingSchool={isAddingNewSchool ? null : schools[0]}
              skipSelectSchoolText={
                isAddingNewSchool ? 'Back' : "I don't work for a school or district"
              }
              onSkipSelectSchool={() => {
                Mixpanel.track('Onboarding Wizard - I dont work at a school clicked', {
                  event_category: 'onboarding',
                  key_action: 'i_dont_work_at_a_school_clicked',
                  is_action: true,
                });
                if (isAddingNewSchool) {
                  setIsAddingNewSchool(false);
                } else {
                  if (schools[0]) {
                    // Remove the school
                    handleDeleteUserSchoolRecord(schools[0].id);
                  }
                  setCurrentStep(1);
                  setSchoolSelectionFormStep('selectSchool');
                }
              }}
              afterSubmitSchool={(response) => {
                Mixpanel.track('Onboarding Wizard - School Information Submitted', {
                  event_category: 'onboarding',
                  key_action: 'school_information_submitted',
                  is_action: true,
                  schools: schools,
                  first_time_user: isFirstTimeUser,
                });
                setCurrentStep(1);
                setSchoolSelectionFormStep('selectSchool');
                if (response?.added_to_org) {
                  setShowFinalStep(true);
                }
                // Show the final step if the user is in a premium org and hasn't seen the welcome to premium nux
                if (hasActiveSubscription(diffitUser)) {
                  const seenNux = JSON.parse(diffitUser.seen_nux_json);
                  if (!seenNux['welcome_to_premium']) {
                    setShowFinalStep(true);
                  }
                }
              }}
              schoolSelectionFormStep={schoolSelectionFormStep}
              setSchoolSelectionFormStep={setSchoolSelectionFormStep}
            />
          )}
        </div>
      )}
      {/* Step 2 */}
      {currentStep === 1 && (
        <div>
          <div className='flex flex-col pb-4'>
            <GradeLevelButtons
              selectedGradeRange={selectedGradeRange}
              setSelectedGradeRange={setSelectedGradeRange}
            />
          </div>
          <div className='flex justify-center'>
            <button
              disabled={selectedGradeRange.length === 0}
              className='bg-emerald-600 text-white font-medium rounded-md p-2 m-2 disabled:opacity-50 w-1/2 h-10'
              onClick={() => {
                Mixpanel.track('Onboarding Wizard - Grade Levels Submitted', {
                  event_category: 'onboarding',
                  key_action: 'grade_levels_submitted',
                  is_action: true,
                  grade_levels: selectedGradeRange,
                  first_time_user: isFirstTimeUser,
                });
                setCurrentStep(2);
                handleSaveSettings('gradeLevels');
              }}>
              Continue
            </button>
          </div>
        </div>
      )}
      {/* Step 3 */}
      {currentStep === 2 && (
        <div>
          <SubjectButtons
            selectedSubjects={selectedSubjects}
            setSelectedSubjects={setSelectedSubjects}
          />
          <div className='flex justify-center pt-4'>
            <button
              className='bg-emerald-600 text-white font-medium rounded-md p-2 m-2 disabled:opacity-50 w-1/2 h-10'
              disabled={selectedSubjects.length === 0 || isSubmitting}
              onClick={() => {
                Mixpanel.track('Onboarding Wizard - Subjects Submitted', {
                  event_category: 'onboarding',
                  key_action: 'subjects_submitted',
                  is_action: true,
                  subjects: selectedSubjects,
                  first_time_user: isFirstTimeUser,
                });
                handleSubmission();
              }}>
              {isSubmitting && (
                <div className='flex w-full items-center justify-center'>
                  <LoadingSpinner />
                </div>
              )}
              {!isSubmitting && (showFinalStep ? 'Continue' : 'Go to Diffit!')}
            </button>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <ThankYouFinalStep setShowWizard={setShowWizard} diffitUser={diffitUser} />
      )}
    </BasicModal>
  );
};

export const GradeLevelButtons = ({ selectedGradeRange, setSelectedGradeRange }) => {
  return (
    <div className='flex flex-col w-full'>
      {GRADE_RANGES.map((grade) => (
        <button
          key={grade}
          className={`${selectedGradeRange.includes(grade) ? 'bg-emerald-600 text-white' : 'text-emerald-600'} border border-emerald-600 font-medium rounded-md p-2 m-2`}
          onClick={() => {
            if (selectedGradeRange.includes(grade)) {
              setSelectedGradeRange(selectedGradeRange.filter((curGrade) => grade !== curGrade));
            } else {
              setSelectedGradeRange([...selectedGradeRange, grade]);
            }
          }}>
          {GRADES_TO_RANGES[grade]}
        </button>
      ))}
    </div>
  );
};

export const SubjectButtons = ({ selectedSubjects, setSelectedSubjects }) => {
  const [isViewMoreExpanded, setIsViewMoreExpanded] = useState(false);

  return (
    <div className='flex flex-col w-full'>
      {SUBJECTS.map((subject) => (
        <button
          key={subject}
          className={`${selectedSubjects.includes(subject) ? 'bg-emerald-600 text-white' : 'text-emerald-600'} border border-emerald-600 font-medium rounded-md p-2 m-2`}
          onClick={() => {
            if (selectedSubjects?.includes(subject)) {
              setSelectedSubjects(selectedSubjects.filter((curSubject) => subject !== curSubject));
            } else {
              setSelectedSubjects([...selectedSubjects, subject]);
            }
          }}>
          {subject}
        </button>
      ))}
      {!isViewMoreExpanded && (
        <button
          className='text-emerald-600 font-medium p-2 m-2 text-sm'
          onClick={() => setIsViewMoreExpanded(!isViewMoreExpanded)}>
          View more
          <ChevronDownIcon className='h-6 w-6 inline-block' />
        </button>
      )}
      {isViewMoreExpanded && (
        <>
          {OTHER_SUBJECTS.map((subject) => (
            <button
              key={subject}
              className={`${selectedSubjects.includes(subject) ? 'bg-emerald-600 text-white' : 'text-emerald-600'} border border-emerald-600 font-medium rounded-md p-2 m-2`}
              onClick={() => {
                if (selectedSubjects?.includes(subject)) {
                  setSelectedSubjects(
                    selectedSubjects.filter((curSubject) => subject !== curSubject)
                  );
                } else {
                  setSelectedSubjects([...selectedSubjects, subject]);
                }
              }}>
              {subject}
            </button>
          ))}
        </>
      )}
    </div>
  );
};

export const ThankYouFinalStep = ({ setShowWizard }) => {
  const { diffitUser, selectedOrg } = useContext(DiffitUserContext);

  return (
    <div className='flex flex-col w-full items-center'>
      <div className='text-sm sm:text-lg text-gray-600'>
        {hasActiveSubscription(diffitUser) && (
          <p className='mb-4'>
            Congrats! You have premium access under
            {selectedOrg?.org.name ? (
              <p>
                {' '}
                under <b>{selectedOrg?.org.name}'s</b> license!
              </p>
            ) : (
              `!`
            )}
          </p>
        )}
        <p className='mb-1.5'>As a teacher using Diffit, you can:</p>
        <ul className='list-disc ml-4 leading-normal'>
          <li>Get resources for any topic or lesson</li>
          <li>Adapt existing materials for any reader</li>
          <li>Export and share as student activities</li>
        </ul>
        <div className='aspect-w-16 aspect-h-9 overflow-hidden'>
          <Image src='/sign-in-success.png' className='object-contain' />
        </div>
      </div>
      <button
        className='bg-emerald-600 text-white font-medium rounded-md p-2 m-2 w-1/2 h-10'
        onClick={() => {
          setShowWizard(false);
        }}>
        Go to Diffit!
      </button>
    </div>
  );
};
