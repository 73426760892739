import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import { clerk, clerkPubKey } from './services/clerkConfig';

import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import './index.css';
import App from './App';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { CustomerioProvider } from './contexts/CustomerIOContext';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

window.addEventListener('vite:preloadError', (event) => {
  window.location.reload();
});

const LDProvider = withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
})(App);

ReactDOM.createRoot(document.getElementById('root')).render(
  <ClerkProvider Clerk={clerk} publishableKey={clerkPubKey}>
    <React.StrictMode>
      <SpeedInsights />
      <CustomerioProvider siteId={import.meta.env.VITE_CUSTOMERIO_SITE_ID}>
        <LDProvider />
      </CustomerioProvider>
    </React.StrictMode>
  </ClerkProvider>
);
