import Clerk from '@clerk/clerk-js';

export let clerk = new Clerk(import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY);
export const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;

const initialClerkLoad = clerk.load;

clerk.load = async () => {
  let retries = 0;
  let maxRetries = 5;
  let retryInterval = 1000;
  let loadWithRetry = async () => {
    while (retries < maxRetries) {
      try {
        const initialLoadResult = await initialClerkLoad();
        return initialLoadResult;
      } catch (error) {
        retries++;
        await new Promise((resolve) => setTimeout(resolve, retryInterval));
      }
    }
  };
  return await loadWithRetry();
};