import { Menu, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Mixpanel } from '../services/Mixpanel.js';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  daysLeftInTrial,
  isFreeTrialUser,
  isActiveIndividualSubscription,
  hasActiveSubscription,
  isPremiumUser,
  userHasEnteredSchoolRecord,
  userHasCompletedTrialExtensionSurvey,
  isInPremiumOrg,
} from '../utils/isPremiumUser';
import PremiumIcon from './PremiumIcon';
import { getUsersShareCode } from '../services/PromoCodeService';
import { getSchoolFromUser } from '../utils/users';
import Image from './Image';
import { Fragment, useContext, useState, useEffect } from 'react';
import { useAuth, useUser } from '@clerk/clerk-react';
import { DiffitUserContext } from '../contexts/DiffitUserContext.jsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isOnActivityPath } from './Header';
import { clearActivitiesCache } from '../routes/Activities/activities.jsx';
import BasicModal from './modals/BasicModal.jsx';
import { ShareSchoolPromoCode } from './ExtendTrialWithSchoolInfo.jsx';
import { ExtendTrialWithSurvey } from './ExtendTrialWithSurvey.jsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ProfileIconMenu = () => {
  const isOnPricingPage = useLocation().pathname.includes('/pricing');
  const { user } = useUser();
  const { diffitUser, selectedOrg, setShowOnboardingWizard, showPendingNotification, signOut } =
    useContext(DiffitUserContext);
  const {
    enableMixMatchPreviewUi,
    returningUserOnboardingExperience,
    newUserOnboardingExperience,
  } = useFlags();

  const { isSignedIn, isLoaded } = useAuth();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [existingPromoCode, setExistingPromoCode] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showExtendTrialModal, setShowExtendTrialModal] = useState(false);
  const shouldShowTrialExtensionOption = !userHasCompletedTrialExtensionSurvey(diffitUser);

  const dismissPromoCodeModal = () => {
    Mixpanel.track('Share School Promo Code Modal Closed');
    setExistingPromoCode(false);
  };

  useEffect(() => {
    if (searchParams.has('extend_with_school') && isLoaded) {
      if (diffitUser) {
        if (
          shouldShowTrialExtensionOption &&
          !isInPremiumOrg(diffitUser) &&
          !isActiveIndividualSubscription(diffitUser)
        ) {
          Mixpanel.track('Showed extend_with_school upgrade modal');
          setShowExtendTrialModal(true);
        } else {
          Mixpanel.track('Ineligible user hit extend_with_school param', {
            userHasEnteredSchoolRecord: userHasEnteredSchoolRecord(diffitUser),
            isInPremiumOrg: isInPremiumOrg(diffitUser),
            isActiveIndividualSubscription: isActiveIndividualSubscription(diffitUser),
          });
        }
        searchParams.delete('extend_with_school');
        setSearchParams(searchParams);
      } else {
        if (!isSignedIn) {
          Mixpanel.track('Showed sign in modal in extend_with_school flow');
          setShowSignInModal(true);
        }
      }
    }
  }, [searchParams, isSignedIn, isLoaded, diffitUser]);

  if (!isSignedIn || !isLoaded) {
    return null;
  }

  return (
    <>
      <ExtendTrialWithSurveyModal
        shown={showExtendTrialModal}
        setShown={(shouldReload) => {
          setShowExtendTrialModal(false);
          if (shouldReload) {
            // reload page for Clerk so that they can see the new premium features
            window.location.reload();
          }
        }}
      />
      {showSignInModal && <SignInModal open={showSignInModal} setOpen={setShowSignInModal} />}
      {existingPromoCode && (
        <BasicModal
          showModal={!!existingPromoCode}
          setShowModal={dismissPromoCodeModal}
          icon={<PremiumIcon className='h-6 w-6 text-purple-600' aria-hidden='true' />}
          iconBackground={'bg-purple-100'}>
          <ShareSchoolPromoCode
            promoCode={existingPromoCode}
            schoolName={getSchoolFromUser(diffitUser).name}
            onClose={dismissPromoCodeModal}
          />
        </BasicModal>
      )}

      <div className='hidden sm:flex sm:items-center'>
        <HelpMenu />
        {!isOnPricingPage && (
          <div>
            <Menu as='div' className='relative ml-3'>
              {({ open, close }) => (
                <>
                  <div>
                    <Menu.Button
                      onClick={() => {
                        Mixpanel.track(`${open ? 'Close' : 'Open'} user menu`);
                      }}
                      className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'>
                      <span className='sr-only'>Open user menu</span>
                      <Image
                        className='h-8 w-8 rounded-full'
                        src={user.profileImageUrl}
                        referrerPolicy='no-referrer'
                        alt=''
                      />
                    </Menu.Button>
                    {showPendingNotification && !open && (
                      <div className='absolute -top-2 -right-2 h-5 w-5 rounded-full bg-red-500 flex items-center justify-center'>
                        <p className='text-white text-xs text-center'>1</p>
                      </div>
                    )}
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items className='absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white divide-y divide-gray-200 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <div>
                        <div className='flex items-center px-4 text-md font-semibold text-emerald-900'>
                          <span>{user.fullName}</span>
                          {isPremiumUser(user, diffitUser) && (
                            <div className='inline-flex ml-2'>
                              <PremiumIcon />
                            </div>
                          )}
                        </div>
                        <div className='px-4 pb-2 text-xs text-gray-500 truncate'>
                          {user.primaryEmailAddress.emailAddress}
                        </div>
                        {diffitUser && !hasActiveSubscription(diffitUser) && (
                          <div className='px-4 pb-2 text-xs'>
                            {isFreeTrialUser(user, diffitUser) ? (
                              <>
                                <div className='italic text-gray-700 pb-0.5'>
                                  {`You have ${
                                    daysLeftInTrial(user, diffitUser) === 0
                                      ? '<1 day'
                                      : daysLeftInTrial(user, diffitUser) === 1
                                        ? '1 day'
                                        : daysLeftInTrial(user, diffitUser) + ' days'
                                  } left in your premium trial.`}
                                </div>
                                {shouldShowTrialExtensionOption ? (
                                  <button
                                    onClick={() => {
                                      close();
                                      setTimeout(() => {
                                        Mixpanel.track(
                                          'Opened trial_extension modal in Avatar Menu',
                                          {
                                            hasExistingTrial: true,
                                            daysLeftInTrial: daysLeftInTrial(user, diffitUser),
                                          }
                                        );
                                        // small timeout to avoid them being on the screen at the same time
                                        setShowExtendTrialModal(true);
                                      }, 100);
                                    }}
                                    className={`text-left text-emerald-500`}>
                                    Click here to extend your trial
                                  </button>
                                ) : (
                                  <>
                                    {userHasCompletedTrialExtensionSurvey(diffitUser) && (
                                      <div>
                                        <button
                                          onClick={async () => {
                                            Mixpanel.track(
                                              'Get (Existing) Share Code in Avatar Menu'
                                            );
                                            const response = await getUsersShareCode();
                                            setExistingPromoCode(response.data.promo_code.code);
                                            close();
                                          }}
                                          className={`text-emerald-500 hover:underline inline`}>
                                          Click here
                                        </button>{' '}
                                        to see your share code
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <span>
                                  You are using Diffit’s free version.{' '}
                                  {shouldShowTrialExtensionOption ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          Mixpanel.track(
                                            'Opened extend_with_school modal in Avatar Menu',
                                            {
                                              hasExistingTrial: false,
                                            }
                                          );
                                          close();
                                          setTimeout(() => {
                                            setShowExtendTrialModal(true);
                                          }, 100);
                                        }}
                                        className={`text-left text-emerald-500`}>
                                        Click here to get 4 more months of premium access, free!
                                      </button>
                                    </>
                                  ) : (
                                    <a
                                      href='https://web.diffit.me/schools'
                                      target='_blank'
                                      className='text-purple-600 hover:text-purple-500'
                                      onClick={() =>
                                        Mixpanel.track('Learn about Premium Clicked', {
                                          isFreeTrialUser: isFreeTrialUser(user, diffitUser),
                                          daysLeftInTrial: daysLeftInTrial(user, diffitUser),
                                        })
                                      }>
                                      Learn about Premium!
                                    </a>
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                        )}
                        {!!diffitUser && diffitUser.active_orgs?.length >= 1 && selectedOrg && (
                          <div className='px-4 pb-2 text-lg text-emerald-500'>
                            <a
                              className={`${
                                selectedOrg.role === 'Admin'
                                  ? 'hover:text-emerald-400'
                                  : 'pointer-events-none'
                              }`}
                              href={`${selectedOrg.role === 'Admin' ? `/org/${selectedOrg.org_id}` : ''}`}>
                              {selectedOrg.org.name}
                            </a>{' '}
                          </div>
                        )}
                      </div>
                      {selectedOrg && selectedOrg.role === 'Admin' && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                Mixpanel.track('Admin Dashboard');
                                navigate('/org/' + selectedOrg.org.id);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                              )}>
                              Manage Users
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              Mixpanel.track('My Resources');
                              navigate('/resources');
                            }}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                            )}>
                            My Resources
                          </a>
                        )}
                      </Menu.Item>

                      {enableMixMatchPreviewUi && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                Mixpanel.track('My Templates (User)');
                                navigate('/templates');
                              }}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                              )}>
                              My Templates
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {!!diffitUser &&
                        (isActiveIndividualSubscription(diffitUser) ||
                          diffitUser.stripe_needs_attention) && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  Mixpanel.track('Manage Subscription');
                                  navigate('/subscription');
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                )}>
                                Manage Subscription{' '}
                                {diffitUser.stripe_needs_attention && (
                                  <ExclamationCircleIcon className='h-5 w-5 self-center self-justify-center text-red-500 inline' />
                                )}
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      {!!diffitUser && diffitUser.is_diffit_admin && (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  navigate('/admin');
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                )}>
                                Diffit Admin
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  Mixpanel.track('Diffit Templates');
                                  navigate('/admin/templates');
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                )}>
                                Diffit Templates
                              </a>
                            )}
                          </Menu.Item>
                        </>
                      )}
                      {newUserOnboardingExperience &&
                        returningUserOnboardingExperience &&
                        !!diffitUser && (
                          <Menu.Item>
                            {({ active }) => (
                              <div className='flex flex-row w-full'>
                                <a
                                  onClick={() => {
                                    setShowOnboardingWizard(true);
                                    Mixpanel.track('Avatar Menu - Selected Profile');
                                  }}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'cursor-pointer block px-4 py-2 text-sm text-gray-700 flex flex-row w-full'
                                  )}>
                                  Profile
                                  {showPendingNotification && open && (
                                    <div className='flex items-center ml-2'>
                                      <div className='h-5 w-5 rounded-full bg-red-500 flex items-center justify-center'>
                                        <p className='text-white text-xs text-center'>1</p>
                                      </div>
                                    </div>
                                  )}
                                </a>
                              </div>
                            )}
                          </Menu.Item>
                        )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={signOut}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                            )}>
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    </>
  );
};

const ExtendTrialWithSurveyModal = ({ shown, setShown }) => {
  return (
    <BasicModal
      showModal={shown}
      setShowModal={() => {
        setShown(false);
      }}>
      <ExtendTrialWithSurvey onClose={setShown} />
    </BasicModal>
  );
};

const HelpMenu = () => {
  if (location.pathname.includes('_wysiwyg')) {
    return null;
  }
  return (
    <div>
      <Menu as='div' className='relative ml-3'>
        {() => (
          <>
            <div>
              <Menu.Button className='flex rounded-full bg-white text-sm text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-500'>
                <span className='sr-only'>Open help menu</span>
                <QuestionMarkCircleIcon className='h-8 w-auto' />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'>
              <Menu.Items className='absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='https://web.diffit.me/professional-development'
                      target='_blank'
                      onClick={() =>
                        Mixpanel.track('Header click', {
                          item: 'Learn More',
                        })
                      }
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}>
                      Learn More
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='https://support.diffit.me/hc/en-us/requests/new'
                      target='_blank'
                      onClick={() =>
                        Mixpanel.track('Header click', {
                          item: 'Contact Us',
                        })
                      }
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}>
                      Contact Us
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
