import { useState, useContext } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Mixpanel } from '../services/Mixpanel';
import SignInButton from './SignInButton';
import Notices from './Notice';
import { useLocation, useNavigate } from 'react-router-dom';
import { isActiveIndividualSubscription } from '../utils/isPremiumUser';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SignInModal from './SignInModal';
import Image from './Image';
import { clearActivitiesCache } from '../routes/Activities/activities';
import { ProfileIconMenu } from './ProfileIconMenu';

// activity/:activityId
// activity/:activityId/:packetId
// activities
// activities/:packetId
const regex = /^\/activity\/[^\/]+(\/[^\/]+)?$|^\/activities(\/[^\/]+)?$/;

const isOnActivityBrowserRoot = (path) => {
  return ['/activities', '/activities/'].includes(path);
};

export const isOnActivityPath = (path) => {
  return regex.test(path);
};

export default function Header({}) {
  const { user } = useUser();
  const { diffitUser, signOut } = useContext(DiffitUserContext);
  const { isSignedIn, isLoaded } = useAuth();
  const { enableMyBinderView } = useFlags();
  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const location = useLocation();
  const isOnPricingPage = location.pathname.includes('/pricing');
  const isOnWysiwygPage = location.pathname.includes('_wysiwyg');
  const isOnAnActivityPage = isOnActivityPath(window.location.pathname);

  if (isOnWysiwygPage) {
    return <></>;
  }

  return (
    <>
      {showSignInModal && <SignInModal open={showSignInModal} setOpen={setShowSignInModal} />}
      <div>
        <Image className='mt-2 h-8 w-auto hidden print:flex' src='/logo.svg' alt='Diffit' />
      </div>
      <Disclosure as='nav' className='bg-white shadow print:hidden'>
        {({ open }) => (
          <>
            <div id='main-nav-bar' className={`mx-auto px-6 sm:px-6 lg:px-8`}>
              <div className='flex h-20 justify-between'>
                <div className='flex'>
                  <a
                    href='/'
                    className='flex flex-col justify-center'
                    onClick={() => Mixpanel.track('Header click', { item: 'Logo' })}>
                    <div>
                      <Image className='h-12 w-auto' src='/logo.svg' alt='Diffit' />
                    </div>
                  </a>
                  {!isSignedIn && isLoaded && !isOnPricingPage && (
                    <div className='hidden sm:ml-10 sm:flex sm:space-x-8'>
                      {/* Current: "border-emerald-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                      <a
                        role='button'
                        onClick={() => {
                          Mixpanel.track('Header click', { item: 'Start New' });
                          navigate('/');
                        }}
                        className={`inline-flex items-center px-1 pt-1 text-sm text-gray-500 border-b-2 border-white hover:border-emerald-300 hover:border-gray-300 hover:text-gray-900`}>
                        Start New
                      </a>
                      <a
                        role='button'
                        onClick={() => {
                          Mixpanel.track('Header click', {
                            item: 'Activities',
                          });
                          clearActivitiesCache();
                          navigate('/activities');
                          if (isOnActivityBrowserRoot(location.pathname)) {
                            window.location.reload();
                          }
                        }}
                        className={`inline-flex items-center  px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 hover:text-gray-900 ${
                          isOnAnActivityPage
                            ? 'border-emerald-500 text-gray-500'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-900'
                        }`}>
                        Activities
                      </a>
                      <a
                        href='https://web.diffit.me/professional-development'
                        target='_blank'
                        onClick={() => Mixpanel.track('Header click', { item: 'Learn More' })}
                        className='inline-flex items-center  border-transparent px-1 pt-1 text-sm text-gray-500 hover:border-gray-300 hover:text-gray-900 border-b-2 border-white hover:border-emerald-300 hover:text-gray-900'>
                        Learn More
                      </a>
                      <a
                        href='https://support.diffit.me/hc/en-us/requests/new'
                        target='_blank'
                        onClick={() => Mixpanel.track('Header click', { item: 'Contact Us' })}
                        className='inline-flex items-center  border-transparent px-1 pt-1 text-sm text-gray-500 hover:border-gray-300 hover:text-gray-900 border-b-2 border-white hover:border-emerald-300 hover:text-gray-900'>
                        Contact Us
                      </a>
                    </div>
                  )}{' '}
                  {isSignedIn && isLoaded && !isOnPricingPage && (
                    <div className='hidden sm:ml-10 sm:flex sm:space-x-8'>
                      <a
                        href='/'
                        onClick={() => Mixpanel.track('Header click', { item: 'Start New' })}
                        className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                        Start New
                      </a>
                      <a
                        onClick={() => {
                          Mixpanel.track('Header click', {
                            item: 'My Resources',
                          });
                          navigate('/resources');
                        }}
                        className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                        My Resources
                      </a>
                      {enableMyBinderView && (
                        <a
                          role='button'
                          onClick={() => {
                            Mixpanel.track('Header click', {
                              item: 'Activities',
                            });

                            clearActivitiesCache();
                            navigate('/activities');
                            if (isOnActivityBrowserRoot(location.pathname)) {
                              window.location.reload();
                            }
                          }}
                          className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                          Activities
                        </a>
                      )}
                    </div>
                  )}
                </div>
                {!isOnPricingPage && (
                  <div className='hidden sm:flex gap-4 self-center'>
                    <SignInButton />
                  </div>
                )}
                {isSignedIn && isLoaded && <ProfileIconMenu />}
                <div className='-mr-2 flex items-center sm:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {/* Mobile menu */}
            <Disclosure.Panel className='sm:hidden'>
              <div className='space-y-1 pt-2 pb-3'>
                {/* Current: "bg-emerald-50 border-emerald-500 text-emerald-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Disclosure.Button
                  onClick={() => {
                    Mixpanel.track('Header click', { item: 'Start New' });
                    navigate('/');
                  }}
                  className={` ${isOnAnActivityPage ? 'text-gray-500' : 'border-emerald-500 bg-emerald-50 text-emerald-700'} w-full text-left block border-l-4 py-2 pl-3 pr-4 text-base font-medium `}>
                  Start New
                </Disclosure.Button>
                {enableMyBinderView && (
                  <Disclosure.Button
                    onClick={() => {
                      Mixpanel.track('Header click', { item: 'Start New' });
                      navigate('/activities');
                    }}
                    className={`${isOnAnActivityPage ? 'border-emerald-500 bg-emerald-50 text-emerald-700' : 'text-gray-500'} w-full text-left block border-l-4  py-2 pl-3 pr-4 text-base font-medium `}>
                    Browse Activities
                  </Disclosure.Button>
                )}
                <Disclosure.Button
                  as='a'
                  href='https://web.diffit.me/professional-development'
                  target='_blank'
                  onClick={() => Mixpanel.track('Header click', { item: 'Learn More' })}
                  className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'>
                  Learn More
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='https://support.diffit.me/hc/en-us/requests/new'
                  target='_blank'
                  onClick={() => Mixpanel.track('Header click', { item: 'Contact Us' })}
                  className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'>
                  Contact Us
                </Disclosure.Button>
                {!isOnPricingPage && (
                  <div className='flex sm:hidden mx-3 self-center'>
                    <SignInButton />
                  </div>
                )}
              </div>
              {isSignedIn && (
                <div className='border-t border-gray-200 pt-4 pb-3'>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      <Image
                        className='h-10 w-10 rounded-full'
                        src={user.profileImageUrl}
                        referrerPolicy='no-referrer'
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-emerald-900'>{user.fullName}</div>
                      <div className='text-sm font-medium text-gray-500'>
                        {user.primaryEmailAddress.emailAddress}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 space-y-1'>
                    <Disclosure.Button
                      as='a'
                      onClick={signOut}
                      className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'>
                      Sign out
                    </Disclosure.Button>
                    <Disclosure.Button
                      as='a'
                      onClick={() => {
                        Mixpanel.track('My Resources');
                        navigate('/resources');
                      }}
                      className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 cursor-pointer'>
                      My Resources
                    </Disclosure.Button>
                    {!!diffitUser &&
                      (isActiveIndividualSubscription(diffitUser) ||
                        diffitUser.stripe_needs_attention) && (
                        <Disclosure.Button
                          as='a'
                          onClick={() => {
                            Mixpanel.track('Manage Subscription');
                            navigate('/subscription');
                          }}
                          className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'>
                          Manage Subscription
                        </Disclosure.Button>
                      )}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Notices />
    </>
  );
}
